@font-face {
  font-family: 'Inter';
  font-weight: normal;
  font-display: block;
  src: url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  font-display: block;
  src: url('/fonts/Inter-Bold.woff2') format('woff2'),
    url('/fonts/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  font-style: italic;
  font-display: block;
  src: url('/fonts/Inter-Italic.woff2') format('woff2'),
    url('/fonts/Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('/fonts/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Lyon';
  font-weight: normal;
  font-display: block;
  src: url('/fonts/LyonDisplay.woff2') format('woff2'),
    url('/fonts/LyonDisplay.woff') format('woff');
}

@font-face {
  font-family: 'LyonMedium';
  font-weight: normal;
  font-display: block;
  src: url('/fonts/LyonDisplay-medium.woff2') format('woff2'),
    url('/fonts/LyonDisplay-medium.woff') format('woff');
}
