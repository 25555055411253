.react-tagsinput > span {
  box-sizing: border-box;
  display: block;
  display: flex;
  flex-wrap: wrap;
  min-height: 44px;
  min-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 0 10px 0 12px;
  width: 25px;
}

.react-tagsinput input {
  border: none;
  height: 44px;
  min-width: 100%;
  padding: 0;
}

.react-tagsinput input:focus {
  outline: none;
}

.react-tagsinput .react-tagsinput-input {
  flex: 1;
  padding: 0;
}
